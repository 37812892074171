import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { PublicRoutesType } from "routes/public/types";

export function logout() {
  Cookies.remove("jwt_token");
  localStorage.removeItem("recoil-persist");
  localStorage.removeItem("info");
  window.location.href = PublicRoutesType.login.abs;
  toast.error("user not logged in")
}
