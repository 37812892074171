import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ProtectedRoutesTypes } from "routes/protected/types";

export function Inventory() {
  const navigate = useNavigate();
  return (
    <Box sx={{ display: "flex", gap: "2rem", justifyContent: "center", p: 4, flexWrap: "wrap" }}>
      <Button
        sx={{ textTransform: "none !important", width: {xs: "100%", lg: "200px"} }}
        onClick={() => navigate(ProtectedRoutesTypes.inventory.show.abs)}
        variant="contained"
      >
        Show Inventory
      </Button>
      <Button
        sx={{ textTransform: "none !important", width: {xs: "100%", lg: "200px"} }}
        onClick={() => navigate(ProtectedRoutesTypes.inventory.create.abs)}
        variant="contained"
      >
        Create Inventory 
      </Button>

      <Button
        sx={{ textTransform: "none !important", width: {xs: "100%", lg: "200px"} }}
        onClick={() => navigate(ProtectedRoutesTypes.inventory.empty.abs)}
        variant="contained"
      >
        Show Empty Inventory
      </Button>
    </Box>
  );
}
