import { ApiPostRoutes } from "api/routes";
import axios from "axios";
import { useMutation } from "react-query";

function searchInventory(name: string) {
  return axios.post(ApiPostRoutes.inventorySearchByName, name);
}

export function useSearchInventoryByName() {
  return useMutation(searchInventory);
}
