import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ProtectedRoutesTypes } from "routes/protected/types";
import { useInventoryDelete } from "./useInventoryDelete.mutation";
import { Confirm } from "components/common";

export function InventoryTable({
  pageSize,
  onPageSizeChange,
  pageNo,
  data,
  onPageChange,
}: any) {
  const navigate = useNavigate();
  const { mutate, isSuccess } = useInventoryDelete();
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [inventoryId, setInventoryId] = useState(0);
  const handleChangePage = (event: unknown, newPage: number) => {
    onPageChange(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    onPageSizeChange(+event.target.value);
    onPageChange(0);
  };

  useEffect(() => {
    if (isSuccess) {
      setOpenAlert(false);
    }
  }, [isSuccess]);

  return (
    <Paper sx={{ width: "100%" }}>
      <Confirm
        title="Are you sure"
        caption="are you sure you want to delete this inventory"
        cancelCta={{ title: "cancel", onClick: () => setOpenAlert(false) }}
        open={openAlert}
        onChange={(e) => setOpenAlert(e)}
        confirmCta={{
          title: "confirm",
          onClick: () => mutate(inventoryId.toString()),
        }}
      />
      <TableContainer sx={{ mt: 5 }} component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">S.N#</TableCell>
              <TableCell align="center">bar code</TableCell>
              <TableCell align="center">category</TableCell>
              <TableCell align="center">name</TableCell>
              <TableCell align="center">price</TableCell>
              <TableCell align="center">quantity</TableCell>
              <TableCell align="center">created at</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.data?.map((data: any, index: number) => {
              return (
                <TableRow key={data.id}>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">{data.barcode}</TableCell>
                  <TableCell align="center">{data.category.name}</TableCell>
                  <TableCell align="center">{data.name}</TableCell>
                  <TableCell align="center">{parseInt(data.price).toLocaleString()}</TableCell>
                  <TableCell align="center">{data.quantity}</TableCell>
                  <TableCell align="center">
                    {moment(data.updated_at).format(
                      "DD / MMM / YYYY  hh:mm:ss A",
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      onClick={() => {
                        setOpenAlert(true);
                        setInventoryId(data.id);
                      }}
                    >
                      Delete
                    </Button>{" "}
                    <Button
                      onClick={() =>
                        navigate(
                          ProtectedRoutesTypes.inventory.edit.abs.replace(
                            ":inventoryId",
                            data.id,
                          ),
                        )
                      }
                      sx={{ ml: 2 }}
                    >
                      edit
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TableCell colSpan={7} align="center">
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={data.total}
                  rowsPerPage={pageSize}
                  page={pageNo}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Paper>
  );
}
