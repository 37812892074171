import { apiDeleteRoutes } from "api/routes";
import axios from "axios";
import dayjs from "dayjs";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useOrdersMutation } from "./userOders.mutation";

export function useDeleteOrderById() {
  const { mutate,} = useOrdersMutation();

  return useMutation(
    (id: any) => {
      return axios.delete(apiDeleteRoutes.deleteOrder.replace(":id", id));
    },
    {
      onSuccess: () => {
        toast.success("order deleted successfully");
        mutate({
          startDate: dayjs().startOf("day").format("DD/MM/YYYY"),
          endDate: dayjs().endOf("day").format("DD/MM/YYYY"),
        });
      },
    }
  );
}
