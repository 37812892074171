import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Autocomplete, Box, Typography } from "@mui/material";
import { ApiGetRoutes } from "api/routes";
import {CustomTextField, Loading} from "components/common";
import { usePaginatedQuery } from "hooks/usePaginatedQuery";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ProtectedRoutesTypes } from "routes/protected/types";
import { InventoryTable } from "./InventoryTable";
import { useSearchBarcode } from "./searchInventoryByBarcode.mutation ";
import { useSearchInventoryByName } from "./searchInventoryByName.mutation";
import { numberInputOnWheelPreventChange } from "global/events";

export function ShowInventory() {
  const [pageNo, setPageNo] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const { data,isLoading: pageLoading } = usePaginatedQuery(
    ApiGetRoutes.inventory,
    pageNo + 1,
    pageSize,
  );
  const [name, setName] = useState<string>("");
  const {
    mutate,
    isLoading,
    data: inventoryData,
  }: any = useSearchInventoryByName();
  const [inventoryList, setInventoryList] = useState([]);
  const debouncedMutate = useCallback(
    _.debounce((name: any) => {
      if (name.length > 0) {
        mutate({ name });
      }
    }, 1000),
    [mutate],
  );

  const navigate = useNavigate();
  const [barcode, setBarcode] = useState();
  const { mutate: searchBarcodeMutation } = useSearchBarcode();
  useEffect(() => {
    debouncedMutate(name); // Use debouncedMutate instead of mutate
  }, [name, debouncedMutate]);

  useEffect(() => {
    if (inventoryData?.data) {
      const mappedData = inventoryData?.data?.map((data: any) => {
        return {
          label: data.name,
          value: data.id,
        };
      });
      setInventoryList(mappedData);
    }
  }, [inventoryData]);

  function handleKeyDown(e: any) {
    if (e.key === "Enter") {
      searchBarCodeHandler();
    }
  }

  function searchBarCodeHandler() {
    searchBarcodeMutation(
      { barcode: barcode },
      {
        onSuccess: ({ data: response }) =>
          navigate(
            ProtectedRoutesTypes.inventory.edit.abs.replace(
              ":inventoryId",
              response.data.id,
            ),
          ),
      },
    );
  }

  return (
    <Box>
      <Loading open={pageLoading}/>
      <Typography sx={{ textAlign: "center" }} variant="h3">
        Inventory
      </Typography>
      <Box sx={{ display: "flex" }}>
        <Autocomplete
          disableClearable
          disablePortal
          id="combo-box-demo"
          onChange={(event: any, value: any) =>
            navigate(
              ProtectedRoutesTypes.inventory.edit.abs.replace(
                ":inventoryId",
                value.value,
              ),
            )
          }
          loadingText="loading..."
          loading={isLoading}
          options={inventoryList}
          sx={{ width: 300 }}
          renderInput={(params) => (
            <CustomTextField
              onChange={(e: any) => setName(e.target.value)}
              value={name}
              {...params}
              label="search by name"
            />
          )}
        />
        <CustomTextField
          sx={{ ml: 2, width: "200px" }}
          label="search by barcode"
          onKeyDown={handleKeyDown}
          onWheel={numberInputOnWheelPreventChange}
          autoFocus
          type="number"
          value={barcode}
          onChange={(e: any) => setBarcode(e.target.valueAsNumber)}
          InputProps={{
            endAdornment: (
              <ArrowRightIcon
                onClick={searchBarCodeHandler}
                sx={{
                  backgroundColor: "black",
                  color: "white",
                  cursor: "pointer",
                }}
              />
            ),
          }}
        />
      </Box>

      {data?.data?.data.length > 0 ? (
        <InventoryTable
          pageSize={pageSize}
          onPageSizeChange={(e: any) => setPageSize(e)}
          pageNo={pageNo}
          onPageChange={(e: any) => setPageNo(e)}
          data={data?.data}
        />
      ) : (
        <>no record found</>
      )}
    </Box>
  );
}
