import { Box, Typography } from '@mui/material'
import moment from 'moment'
import { forwardRef } from 'react';
import logoImage from "assets/common/logo.png";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const Print = forwardRef(({ data }: any, ref) => {
  return (
    <Box ref={ref}>
      <Box sx={{ display: "flex", p: 1, gap: '12px', justifyContent: "space-between" }}>
        <Box src={logoImage} sx={{ width: "100px", height: "100px" }} component="img" />
        <Box sx={{ alignSelf: 'center' }}>
          <Typography sx={{ fontSize: "10px" }}><b>Address :</b> 107-R phase 2 Dha lahore</Typography>
          <Typography sx={{ fontSize: "10px" }}><b>Number :</b> 03004629596</Typography>
          <Typography sx={{ fontSize: "10px" }}><b>Number :</b> 03234349619</Typography>
          <Typography sx={{ fontSize: "10px" }}><b>Cashier :</b> {data?.order_by}</Typography>
        </Box>
      </Box>
      <Box sx={{ display: "flex", p: 1, gap: '12px', justifyContent: "space-between", mt: 2 }}>
        <Box>
          <Typography sx={{ fontSize: "10px" }}><b>Date:</b> {moment(data?.created_at).format('D-MMMM-yyyy')}</Typography>
        </Box>
        <Box>
          <Typography sx={{ fontSize: "10px" }}><b>Bill# :</b> {data?.id}</Typography>
          <Typography sx={{ fontSize: "10px" }}><b>Time:</b> {moment(data?.created_at).format(' h:mm a')}</Typography>
        </Box>
      </Box>
      <PrintTable data={data} />
      <Box sx={{ mt: 2, p: 1, textAlignLast: "right" }}>
        <Typography sx={{ fontSize: "10px" }} ><b>Total:</b> {data?.total.toLocaleString()}</Typography>
        {data?.discount && <Typography sx={{ fontSize: "10px" }}><b>discount:</b> {data?.discount || 0}% </Typography>}
        {data?.subtraction && <Typography sx={{ fontSize: "10px" }}><b>Less:</b> {data?.subtraction?.toLocaleString() || 0} </Typography>}
        <Typography sx={{ fontSize: "10px" }}><b>Grand total:</b> {data?.grandTotal.toLocaleString()}</Typography>
      </Box>
    </Box>
  )
})

export default Print;

function PrintTable({ data }: any) {
  return <TableContainer sx={{ mt: 1, borderRadius: "0px", boxShadow: "none" }} component={Paper}>
    <Table sx={{
      fontSize: "10px", "& .MuiTableCell-root": {
        borderRadius: "0px"
      },
    }} aria-label="simple table">
      <TableHead>
        <TableRow >
          <TableCell sx={{ padding: "1rem 0" }} align="left"><Typography sx={{ pl: 1, fontSize: "10px" }}><b>Name</b></Typography></TableCell>
          <TableCell sx={{ padding: "1rem 0" }} align="center"><Typography sx={{ fontSize: "10px" }}><b>Quantity</b></Typography></TableCell>
          <TableCell sx={{ padding: "1rem 0" }} align="center"><Typography sx={{ fontSize: "10px" }}><b>Rate</b></Typography></TableCell>
          <TableCell sx={{ padding: "1rem 0" }} align="center"><Typography sx={{ fontSize: "10px" }}><b>Price</b></Typography></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data?.order_details.map((data: any) => {
          return (
            <TableRow key={data.id}>
              <TableCell sx={{ padding: "1rem 0", wordWrap: "break-word", maxWidth: "70px !important" }} align="left" scope="row">
                <Typography sx={{ pl: 1, fontSize: "10px" }}><b>{data.name}</b></Typography>
              </TableCell>
              <TableCell sx={{ padding: "1rem 0" }} align="center"><Typography sx={{ fontSize: "10px" }}><b>{data.quantity.toLocaleString()}</b></Typography></TableCell>
              <TableCell sx={{ padding: "1rem 0" }} align="center"><Typography sx={{ fontSize: "10px" }}><b>{data.price.toLocaleString()}</b></Typography></TableCell>
              <TableCell sx={{ padding: "1rem 0" }} align="center">
                <Typography sx={{ fontSize: "10px" }}><b>{(data.quantity * data.price).toLocaleString()}</b></Typography>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  </TableContainer>
}
