import { ApiPostRoutes } from "api/routes";
import { userAtom } from "atoms/user.atom";
import axios from "axios";
import Cookies from "js-cookie";
import { useMutation } from "react-query";
import { useSetRecoilState } from "recoil";
import { ProtectedRoutesTypes } from "routes/protected/types";
import { toast } from "react-toastify";

function signIn(data: any) {
  return axios.post(ApiPostRoutes.login, data);
}

export function useSignInMutation() {
  const setUserProfileState = useSetRecoilState(userAtom);

  return useMutation(signIn, {
    onError: ({ response }) => {
      const errors = response.data.msg;
      errors.forEach((element: string) => {
        toast.error(element);
      });
    },
    onSuccess: (object: any) => {
      const data = object.data.data;
      Cookies.set("jwt_token", data.jwt_token, {
        expires: (1 / 1440) * data.token_expires_at,
      });
      setUserProfileState({
        name: data.name,
        email: data.email,
        loggedIn: true,
        roles: [...data?.roles],
      });
      window.location.pathname = ProtectedRoutesTypes.home.abs;
    },
  });
}
