import axios from "axios";
import { useQuery } from "react-query";

export const usePaginatedQuery = (
  apiEndpoint: any,
  pageNumber: any,
  pageSize: any
) => {
  return useQuery([apiEndpoint, pageNumber, pageSize], async () => {
    return axios.get(`${apiEndpoint}?page_size=${pageSize}&page=${pageNumber}`);
  });
};
