import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { ProtectedRoutesTypes } from "routes/protected/types";
import { Confirm } from "components/common";
import { useState } from "react";
import { useDeleteOrderById } from "../userDeletOrderById.mutation";
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {Box} from "@mui/material";

export function OrdersTable({ data, setResponse }: any) {
  const navigate = useNavigate();
  const { mutate } = useDeleteOrderById();
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [orderId, setOrderId] = useState<number>(0);

  function deleteHandler(id: number) {
    setOpenAlert(true);
    setOrderId(id);
  }

  return data?.length === 0 ? (
    <p>no record found</p>
  ) : (
    <TableContainer sx={{ mt: 5 }} component={Paper}>
      <Confirm
        title="Are you sure"
        caption="Are you sure you want to delete this order"
        cancelCta={{ title: "cancel", onClick: () => setOpenAlert(false) }}
        open={openAlert}
        onChange={(e) => setOpenAlert(e)}
        confirmCta={{
          title: "confirm",
          onClick: () => mutate(orderId, {
            onSuccess: () => {
              setResponse((prevData: any) => {
                const totalGrand = prevData.netTotal;
                const updatedOrders = [...prevData.orders];
                const selectedElement = updatedOrders.find((order) => order.id === orderId);
                if (selectedElement) {
                  const index = updatedOrders.indexOf(selectedElement);
                  if (index !== -1) {
                    updatedOrders.splice(index, 1);
                    return { ...prevData, netTotal: totalGrand - selectedElement.grandTotal, orders: updatedOrders };
                  }
                }
                return prevData;
              });
            },
            onSettled: ()=> setOpenAlert(false)
          })
        }}
      />
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center"><b>S.N#</b></TableCell>
            <TableCell align="center"><b>Order Number</b></TableCell>
            <TableCell align="center"><b>Total</b></TableCell>
            <TableCell align="center"><b>Discount</b></TableCell>
            <TableCell align="center"><b>Less</b></TableCell>
            <TableCell align="center"><b>Grand total</b></TableCell>
            <TableCell align="center"><b>Order date</b></TableCell>
            <TableCell align="center"><b>Actions</b></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((order: any,index: number) => { // Renamed 'data' to 'order'
            return (
              <TableRow key={order.id}>
                <TableCell align="center">{index+1}</TableCell>
                <TableCell align="center">{order.id}</TableCell>
                <TableCell align="center">{order?.total?.toLocaleString()}</TableCell>
                <TableCell align="center">{order?.discount || 0}%</TableCell>
                <TableCell align="center">{order?.subtraction?.toLocaleString() || 0}</TableCell>
                <TableCell align="center">{order?.grandTotal?.toLocaleString()}</TableCell>
                <TableCell align="center">
                  {moment(order.updated_at).format("DD/MMM/yyyy hh:mm A")} {/* Corrected the date format */}
                </TableCell>
                <TableCell sx={{width:"10%"}} align="center">
                  <Box sx={{display: "flex", gap: "5px",justifyContent: "center"}}>
                    <VisibilityIcon
                      onClick={() => navigate(ProtectedRoutesTypes.billing.billingOrder.abs.replace(":orderId", order.id))}
                      sx={{ backgroundColor: "green",color: "white",borderRadius: "25px", padding: "10px",cursor: "pointer"}}
                    />
                    <DeleteIcon
                      onClick={() => deleteHandler(order.id)}
                      sx={{ backgroundColor: "red",color: "white",borderRadius: "25px", padding: "10px",cursor: "pointer"}}
                    />
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
