import { Box, Button, Typography } from "@mui/material";
import { ApiGetRoutes } from "api/routes";
import Print from "components/common/Print";
import { useRef } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import ReactToPrint from 'react-to-print';
import { ProtectedRoutesTypes } from "routes/protected/types";
import { OrderTable } from "./OrderTable";
import {Loading} from "components/common";

export function Order() {
  const { orderId }  = useParams();
  let componentRef: any = useRef();
  const navigate = useNavigate();
  interface Query {
    data: any;
    isLoading: boolean;
  }

  const { data, isLoading }: Query = useQuery({
    queryKey: orderId ? [ApiGetRoutes.order.replace(":orderId", orderId)] : [],
  });

  return (
    <Box sx={{ height: "100vh", padding: "2px" }}>
      <Typography sx={{ textAlign: "center" }} variant="h1">Order</Typography>
      {!isLoading && <Box sx={{ display: "none" }}><Print ref={componentRef} data={data} /></Box>}
      {!isLoading && <OrderTable data={data} />}
      <Loading open={isLoading}/>
      <Box sx={{ display: "flex", justifyContent: "center", gap: "2rem" }}>
        <ReactToPrint
          trigger={() => <Button variant="contained">Print this out!</Button>}
          content={() => componentRef.current || null}
          onAfterPrint={() =>  navigate(ProtectedRoutesTypes.home.abs)}
        />
        <Button variant="contained" onClick={() => navigate(ProtectedRoutesTypes.home.abs)}>Go To Billing</Button>
      </Box>
    </Box>
  );
}
