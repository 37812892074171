import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { PublicRoutesType } from 'routes/public/types';
import { CustomLink } from 'components/common';
import logoImage from "assets/common/logo.png";

function Header() {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };


  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const routes = [
    {
      id: 0,
      tile: "home",
      link: PublicRoutesType.home
    },
    {
      id: 1,
      tile: "about",
      link: PublicRoutesType.about
    },
    {
      id: 2,
      tile: "login",
      link: PublicRoutesType.login
    }
  ]

  return (
    <AppBar position="static">
      <Container sx={{ maxWidth: "100% !important",background: "white" }}>
        <Toolbar disableGutters>
          <Box component="img" sx={{ display: {xs: 'none', md: 'block'}, width: "100px" }} src={logoImage} alt="" />

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              sx={{ color: "black" }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {routes.map((route: any) => {
                return <MenuItem key={route.id} onClick={handleCloseNavMenu}>
                  <CustomLink  to={route.link.abs}>{route.tile}</CustomLink>
                </MenuItem>
              })}
            </Menu>
          </Box>
          <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
          <Box component="img" sx={{ display: {xs: 'flex', md: 'none'}, width: "100px" }} src={logoImage} alt="" />

          <Box sx={{ ml: 2,flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {routes.map((route) => {
              return <CustomLink key={route.id} sx={{ mr: 2 }} onClick={handleCloseNavMenu} to={route.link.abs}>{route.tile}</CustomLink>
            })}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Header;
