import { ApiGetRoutes } from "api/routes";
import axios from "axios";
import { useQuery } from "react-query";

function showUsers(){
  return axios.get(ApiGetRoutes.users)
}

export function useShowUsers(){
  return useQuery(ApiGetRoutes.users,showUsers)
}
