import Cookies from "js-cookie";
import { useLogout } from "hooks/useLogout";

interface RequiresLoginProps {
  children: any;
}

export const RequiresLogin = ({ children }: RequiresLoginProps) => {
  const { mutate } = useLogout()
  
  return Cookies.get("jwt_token") ? children : mutate();
};
