import { ApiGetRoutes } from "api/routes";
import axios from "axios";
import { useQuery } from "react-query";

function emptyInventory(){
  return axios.get(ApiGetRoutes.getEmptyInventory)
}
export function useEmptyInventory(){
  return useQuery(ApiGetRoutes.getEmptyInventory,emptyInventory, {
    enabled : true
  })
}
