import { ApiGetRoutes } from "api/routes";
import axios from "axios";
import { useQuery } from "react-query";

function lastOrder(){
  return axios.get(ApiGetRoutes.getLastOrder)
}

export  function useLastOrder(){
  return useQuery('lastOrder',lastOrder,{
    enabled: true
  })
}
