import { RequiresLogin } from "authentication/RequiresLogin";
import {
  BillingPage,
  CreateInventoryPage,
  OrderPage,
  OrdersPage,
  InventoryPage,
  ShowInventoryPage,
  EditInventoryPage,
  UsersPage,
  CreateUsersPage,
  EmptyInventoryPage,
  ShowUsersPage,
} from "pages/protected";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoutesTypes } from "./types";
import { ProtectedLayout } from "layout/ProtectedLayout";
import RequiresRole from "authentication/RequiresRole";

const ProtectedRoute = () => {
  return (
    <RequiresLogin>
      <RequiresRole>
        <Routes>
          <Route element={<ProtectedLayout />}>
            <Route path={ProtectedRoutesTypes.home.rel} element={<BillingPage />} />
            <Route path={ProtectedRoutesTypes.billing.rel}>
              <Route index element={<BillingPage />} />
              <Route
                path={ProtectedRoutesTypes.billing.billingOrder.rel}
                element={<OrderPage />}
              />
            </Route>
            <Route path={ProtectedRoutesTypes.inventory.rel}>
              <Route index element={<InventoryPage />} />
              <Route
                path={ProtectedRoutesTypes.inventory.edit.rel}
                element={<EditInventoryPage />}
              />
              <Route
                path={ProtectedRoutesTypes.inventory.create.rel}
                element={<CreateInventoryPage />}
              />
              <Route
                path={ProtectedRoutesTypes.inventory.show.rel}
                element={<ShowInventoryPage />}
              />
              <Route
                path={ProtectedRoutesTypes.inventory.empty.rel}
                element={<EmptyInventoryPage />}
              />
            </Route>
            <Route
              path={ProtectedRoutesTypes.orders.rel}
              element={<OrdersPage />}
            />
            <Route
              path={ProtectedRoutesTypes.users.rel}
            >
              <Route index element={<UsersPage />} />
              <Route
                path={ProtectedRoutesTypes.users.create.rel}
                element={<CreateUsersPage />}
              />
              <Route
                path={ProtectedRoutesTypes.users.show.rel}
                element={<ShowUsersPage />}
              />
            </Route>
            <Route
              path={ProtectedRoutesTypes.others.rel}
              element={<>no route found</>}
            />
          </Route>
        </Routes>
      </RequiresRole>
    </RequiresLogin>
  );
};

export default ProtectedRoute;
