import { Box, Typography } from '@mui/material';
import { forwardRef } from 'react';
import Barcode from "react-barcode";

interface barcodeProps {
  barcode: string;
  name: string;
}

const PrintBarcode = forwardRef(({ barcode,name }: barcodeProps, ref) => {
  return (
    <Box sx={{mt: "10px",ml: "5%"}} ref={ref}>
      <Barcode format="CODE128" value={barcode} />
      <Typography sx={{ padding: 0,fontSize: "11px",ml: "7%" }}>{name}</Typography>
    </Box>
  )
});

export default PrintBarcode;
