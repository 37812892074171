import { ApiPostRoutes } from "api/routes";
import axios from "axios";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

function searchBarcode(barcode: any) {
  return axios.post(ApiPostRoutes.inventorySearchByBarcode, barcode);
}

export function useSearchBarcode() {
  return useMutation(searchBarcode, {
    onError: () => {
      toast.error("barcode not found");
    },
  });
}
