import { ApiPostRoutes } from "api/routes";
import axios from "axios";
import Cookies from "js-cookie";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { PublicRoutesType } from "routes/public/types";
import { toast } from "react-toastify";
import { userAtom } from "atoms/user.atom";
import { useResetRecoilState } from "recoil";

function userLogout() {
  return axios.post(ApiPostRoutes.logout);
}

export function useLogout() {
  const navigate = useNavigate();
  const resetState = useResetRecoilState(userAtom)

  const handleLogout = () => {
    Cookies.remove("jwt_token");
    localStorage.removeItem("recoil-persist");
    localStorage.removeItem("info");
    resetState()
    navigate(PublicRoutesType.login.abs);
  };

  return useMutation(userLogout, {
    onSuccess: () => {
      toast.success("logged out");
      handleLogout();
    },
  });
}
