import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  Box,
} from "@mui/material";
import { useState } from "react";
import { Operation } from "components/Protected/Billing/Billing";
import _ from "lodash";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import PercentIcon from "@mui/icons-material/Percent";
import { CustomTableHeader } from "./BillingTable.styles";
import { CustomTextField } from "components/common";
import { numberInputOnWheelPreventChange } from "global/events";
interface BillingTableProps {
  data: any;
  onRemoveItem: any;
  onQuantityChange: any;
  onDiscountChange: any;
  discount: any;
  subtractedAmount: any;
  onSubtractedAmountChange: any;
}

export function BillingTable({
  data,
  onRemoveItem,
  onQuantityChange,
  onDiscountChange,
  discount,
  subtractedAmount,
  onSubtractedAmountChange,
}: BillingTableProps) {
  const [appliedDiscount, setAppliedDiscount] = useState<number>(0);
  const [appliedSubtraction, setAppliedSubtraction] = useState<number>(0);

  const totalSum = _.sumBy(data, function (o: any) {
    return o.price * o.quantity;
  });

  function removeItemHandler(itemID: any) {
    onRemoveItem(itemID);
  }

  function discountHandler() {
    setAppliedDiscount((discount * totalSum) / 100);
  }

  function handleKeyDown(e: any) {
    if (e.key === "Enter") {
      discountHandler();
    }
  }

  function subtractionKeyDown(e: any) {
    if (e.key === "Enter") {
      amountSubtractionHandler();
    }
  }

  function amountSubtractionHandler() {
    setAppliedSubtraction(parseInt(subtractedAmount));
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <CustomTableHeader align="center">Product code</CustomTableHeader>
            <CustomTableHeader align="center">product name</CustomTableHeader>
            <CustomTableHeader align="center">quantity</CustomTableHeader>
            <CustomTableHeader align="center">unit price</CustomTableHeader>
            <CustomTableHeader align="center">total price</CustomTableHeader>
            <CustomTableHeader align="center">actions</CustomTableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {[...data].map((row: any,index: number) => (
            <TableRow key={row?.id}>
              <TableCell align="center" component="th" scope="row">
                {row?.barcode || "-"}
              </TableCell>
              <TableCell align="center">{row?.name}</TableCell>
              <TableCell width="26%" align="center">
                <Button
                  onClick={() => onQuantityChange(Operation.add, row?.id)}
                >
                  +
                </Button>
                {row?.quantity}
                <Button
                  onClick={() => onQuantityChange(Operation.subtract, row?.id)}
                >
                  -
                </Button>
              </TableCell>
              <TableCell align="center">
                {parseInt(row?.price).toLocaleString()}
              </TableCell>
              <TableCell align="center">
                {(row?.price * row?.quantity).toLocaleString()}
              </TableCell>
              <TableCell align="center">
                <Button onClick={() => removeItemHandler(index)}>
                  remove
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={5} align="right">
              <Box>Total: {totalSum.toLocaleString()}</Box>
              <Box>Discount: {appliedDiscount.toLocaleString()}</Box>
              <Box>Less: {(appliedSubtraction).toLocaleString()}</Box>
              <Box>
                Grand total:
                {(
                  totalSum -
                  appliedDiscount -
                  appliedSubtraction
                ).toLocaleString()}
              </Box>
            </TableCell>
            <TableCell>
              <Box sx={{ display: "flex", gap: 2 }}>
                <CustomTextField
                  sx={{ width: "200px" }}
                  InputProps={{
                    endAdornment: (
                      <ArrowRightIcon
                        onClick={discountHandler}
                        sx={{
                          backgroundColor: "black",
                          color: "white",
                          cursor: "pointer",
                        }}
                      />
                    ),
                    startAdornment: <PercentIcon sx={{ fontSize: "18px" }} />,
                  }}
                  value={discount}
                  onChange={(e: any) =>
                    onDiscountChange(e.target.valueAsNumber)
                  }
                  type="number"
                  label="Discount"
                  onKeyDown={handleKeyDown}
                  onWheel={numberInputOnWheelPreventChange}
                />
                <CustomTextField
                  label="Less"
                  sx={{ width: "200px" }}
                  value={subtractedAmount}
                  onChange={(e: any) =>
                    onSubtractedAmountChange(e.target.value)
                  }
                  onKeyDown={subtractionKeyDown}
                  InputProps={{
                    endAdornment: (
                      <ArrowRightIcon
                        onClick={amountSubtractionHandler}
                        sx={{
                          backgroundColor: "black",
                          color: "white",
                          cursor: "pointer",
                        }}
                      />
                    ),
                  }}
                />
              </Box>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
