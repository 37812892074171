import { ApiPostRoutes } from "api/routes";
import axios from "axios";
import { useMutation } from "react-query";

function orders(orderParams: any) {
  return axios.post(ApiPostRoutes.orders, orderParams);
}
export function useOrdersMutation() {
  return useMutation(orders);
}
