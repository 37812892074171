import { Roles } from "global/constants";
import { useIsPathValid } from "global/urlUtils";
import { useUserProfile } from "global/useUserProfile";
import _ from "lodash";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ProtectedRoutesTypes } from "routes/protected/types";

interface RequireRolesProps {
  children: any
}

function RequiresRole({ children }: RequireRolesProps): any {
  const { userRoles } = useUserProfile()
  const existingRoutes = _.map(ProtectedRoutesTypes, 'abs');
  const billingPage = useIsPathValid(window.location.pathname, ProtectedRoutesTypes.billing.billingOrder.abs)

  const moderatorRoutes = existingRoutes.filter((route: any) => route === ProtectedRoutesTypes.home.abs || route === ProtectedRoutesTypes.billing.abs)
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (userRoles.includes(Roles.Moderator)) {
      if (!billingPage) {
        if (!(moderatorRoutes.includes(location.pathname))) {
          toast.error("not allowed")
          navigate(ProtectedRoutesTypes.home.abs)
        }
      }

    }
  }, []);


  return (
    <div>
      {children}
    </div>
  )
}

export default RequiresRole
