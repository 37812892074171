import { ApiPostRoutes } from "api/routes";
import axios from "axios";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

function createUser(userData: any) {
  return axios.post(ApiPostRoutes.signup, userData);
}

export function useCreateUser() {
  return useMutation(createUser, {
    onSuccess: () => {
      toast.success("user created successfully");
    },
    onError({ response }) {
      const errors = response.data.msg;
      errors.forEach((element: string[]) => {
        toast.error(element);
      });
    },
  });
}
