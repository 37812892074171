import { Box, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { ProtectedRoutesTypes } from 'routes/protected/types';

function Users() {
  const navigate = useNavigate();

  return (
    <Box sx={{ display: "flex", gap: "2rem", justifyContent: "center", p: 4, flexWrap: "wrap" }}>
      <Button sx={{ width: { xs: "100%", lg: "200px" } }} variant="contained" onClick={() => navigate(ProtectedRoutesTypes.users.create.abs)}>Create User</Button>
      <Button sx={{ width: { xs: "100%", lg: "200px" } }} variant="contained" onClick={() => navigate(ProtectedRoutesTypes.users.show.abs)}>Show Users</Button>
    </Box>
  )
}

export default Users
