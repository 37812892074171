import { userAtom } from "atoms/user.atom";
import { useRecoilValue } from "recoil";


export function useUserProfile(){
  const userProfileState = useRecoilValue(userAtom);

  return {
    name: userProfileState.name,
    email: userProfileState.email,
    loggedIn: userProfileState.loggedIn,
    userRoles: userProfileState.roles,
  }

}
