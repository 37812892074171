import { apiPutRoutes } from "api/routes";
import axios from "axios";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { ProtectedRoutesTypes } from "routes/protected/types";
import { toast } from "react-toastify";

function updateInventory(inventoryParams: any) {
  return axios.put(apiPutRoutes.updateInventory, inventoryParams);
}
export function useUpdateInventory() {
  const navigate = useNavigate();
  return useMutation(updateInventory, {
    onSuccess: () => {
      toast.success("updated successfully");
      navigate(ProtectedRoutesTypes.inventory.abs);
    },
  });
}
