import { useMutation } from "react-query";
import axios from "axios";
import { ApiPostRoutes } from "api/routes";

function createInventory(inventory: any) {
  return axios.post(ApiPostRoutes.createInventory, inventory);
}

export function useCreateInventoryMutation() {
  return useMutation(createInventory);
}
