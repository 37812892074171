import axios from "axios";
import { ToastProvider } from "components/common/ToastProvider";
import { queryClient } from "global/urlUtils";
import Cookies from "js-cookie";
import { QueryClientProvider } from "react-query";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { ProtectedRoute } from "routes/protected";
import { PublicRoute } from "routes/public";
import { logout } from "shared/utils";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.common = {
  Authorization: `Bearer ${Cookies.get("jwt_token")}`,
  Accept: "application/json",
};

axios.interceptors.response.use(
  (res) => {
    // Add configurations here
    if (res.status === 201) {
      console.log("Posted Successfully");
    }
    return res;
  },
  (err) => {
    if (err?.response?.data?.message === "Unauthenticated.") {
      logout();
    }
    return Promise.reject(err);
  },
);

const App = () => {
  return (
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <ToastProvider />
        <BrowserRouter>
          <Routes>
            <Route path={"/admin/*"} element={<ProtectedRoute />} />
            <Route path={"/*"} element={<PublicRoute />} />
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </RecoilRoot>
  );
};

export default App;
