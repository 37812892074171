
export const ProtectedRoutesTypes = {
 
    home :{
      rel: "",
      abs: "/admin"
    },
    orders: {
      rel: "orders",
      abs: "/admin/orders"
    },
    billing :{
      rel: "billing",
      abs: "/admin/billing",
      billingOrder: {
        rel: ":orderId",
        abs: "/admin/billing/:orderId"
      }
    },
    inventory :{
      rel: "inventory",
      abs: "/admin/inventory" ,
      edit: {
        rel: ":inventoryId",
        abs: "/admin/inventory/:inventoryId"
      },
      create: {
        rel: "create",
        abs: "/admin/inventory/create"
      },
      empty :{
        rel: "empty",
        abs: "/admin/inventory/empty"
      },
      show :{
        rel: "show",
        abs: "/admin/inventory/show"
      },
    },
    users :{
      rel: "users",
      abs: "/admin/users",
      create: {
        rel: "create",
        abs: "/admin/users/create",
      },
      show: {
        rel: "show",
        abs: "/admin/users/show"
      }
    },
    others: {
      rel: "*",
      abs: "/admin/*"
    }
}
