import {
  SxProps,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

interface ConfirmCta {
  title: string;
  onClick: () => void;
  sx?: SxProps;
}

interface CancelCta {
  title: string;
  onClick: () => void;
  sx?: SxProps;
}

interface ConfirmProps {
  open: boolean;
  confirmCta: ConfirmCta;
  cancelCta: CancelCta;
  caption: string;
  title: string;
  onChange: (open: boolean) => void;
}

export default function Confirm({ onChange, caption, title, open, confirmCta, cancelCta }: ConfirmProps) {

  return (
    <Dialog
      open={open}
      onClose={() => onChange(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {caption}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancelCta.onClick}>{cancelCta.title}</Button>
        <Button onClick={confirmCta.onClick} autoFocus>
          {confirmCta.title}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
