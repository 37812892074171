import { CreateUser } from "components/Protected/CreateUser"

function CreateUsersPage() {
  return (
    <CreateUser />
  )
}

export default CreateUsersPage

