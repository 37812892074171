import { ApiPostRoutes } from "api/routes";
import axios from "axios";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

function userOrderById(orderId: any) {
  return axios.post(ApiPostRoutes.orderById, orderId);
}

export function useOrderByIdMutation() {
  return useMutation(userOrderById, {
    onError({ response }) {
      const errors = response.data.msg;
      errors.forEach((element: string[]) => {
        toast.error(element);
      });
    },
  });
}
