import { Box, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import logoImage from "assets/common/logo.png";
import moment from "moment";

interface OrderTableProps {
  data: any;
}

export function OrderTable({ data }: OrderTableProps) {
  return (
    <Box sx={{ padding: "2rem" }}>
      <Box sx={{ display: "flex", gap: '6px', justifyContent: "space-between" }}>
        <Box src={logoImage} sx={{ width: "100px", height: "100px" }} component="img" />
        <Box sx={{ alignSelf: 'center' }}>
          <Typography><b>Address :</b> 107-R phase 2 Dha lahore</Typography>
          <Typography><b>Number :</b> 03004629596</Typography>
          <Typography><b>Number :</b> 03234349619</Typography>
          <Typography><b>Cashier :</b> {data.order_by}</Typography>
        </Box>
      </Box>
      <Box sx={{ display: "flex", gap: '12px', justifyContent: "space-between", mt: 2 }}>
        <Box>
          <Typography><b>Date:</b> {moment(data?.created_at).format('D-MMMM-yyyy')}</Typography>
        </Box>
        <Box>
          <Typography><b>Bill# :</b> {data?.id}</Typography>
          <Typography><b>Time:</b> {moment(data?.created_at).format(' h:mm a')}</Typography>
        </Box>
      </Box>
      <TableContainer sx={{ mt: 5, borderRadius: "0px", boxShadow: "none" }} component={Paper}>
        <Table sx={{
          fontSize: "10px", "& .MuiTableCell-root": {
            borderRadius: "0px"
          },

        }} aria-label="simple table">
          <TableHead>
            <TableRow >
              <TableCell align="center"><Typography ><b>Name</b></Typography></TableCell>
              <TableCell align="center"><Typography><b>Quantity</b></Typography></TableCell>
              <TableCell align="center"><Typography><b>Rate</b></Typography></TableCell>
              <TableCell align="center"><Typography><b>Price</b></Typography></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.order_details.map((data: any) => {
              return (
                <TableRow key={data.id}>
                  <TableCell sx={{ padding: "1rem 0", wordWrap: "break-word", maxWidth: "70px !important" }} align="center" scope="row">
                    <Typography ><b>{data.name}</b></Typography>
                  </TableCell>
                  <TableCell align="center"><Typography><b>{data.quantity}</b></Typography></TableCell>
                  <TableCell align="center"><Typography><b>{(parseInt(data?.price)).toLocaleString()}</b></Typography></TableCell>
                  <TableCell align="center">
                    <Typography><b>{(data?.quantity * data?.price).toLocaleString()}</b></Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: "flex",mt: 4, justifyContent: "end", gap: "4rem" }}>
        <Box sx={{ fontSize: "22px" }}>
          <Box><b>Less: </b>{data?.subtraction?.toLocaleString() || 0} </Box>
          <Box><b>Discount: </b>{data?.discount || 0}% </Box>
        </Box>
        <Box sx={{ fontSize: "22px" }} >
          <Box><b>Total: </b> {data?.total?.toLocaleString()}</Box>
        <Box><b>Grand total: </b>{data?.grandTotal?.toLocaleString()}</Box>
        </Box>
      </Box>
    </Box>
  );
}
