import { ApiPostRoutes} from "api/routes";
import axios from "axios";
import { useMutation, useQuery} from "react-query";
import { toast } from "react-toastify";

function createOrder(orderData: any) {
  return axios.post(ApiPostRoutes.createOrder, orderData);
}

export function useCreateOrder() {

  return useMutation(createOrder, {
    onError: (error: any) => {
      const errorMsgs = error.response.data.msg;
      errorMsgs.forEach((error: any) => {
        toast.error(error);
      });
    },
  });
}
