import {EmptyInventory} from 'components/Protected/Inventory/EmptyInventory'
import React from 'react'

function EmptyInventoryPage() {
  return (
    <EmptyInventory />
  )
}

export default EmptyInventoryPage
