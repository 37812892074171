import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useShowUsers } from './useShowUsers.query';
import { useDeleteUser } from './useDeleteUser.mutation';
import { Button } from '@mui/material';
import { Confirm, Loading } from 'components/common';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useUserProfile } from 'global/useUserProfile';

function ShowUsers() {
  const { data, isLoading } = useShowUsers();
  const { mutate } = useDeleteUser()
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [userId, setUserId] = useState<number>();
  const { email } = useUserProfile();
  const [loading, setLoading] = useState<boolean>(false);

  function deleteHandler(id: any) {
    setAlertOpen(true)
    setUserId(id)
  }

  return (
    <>
      <Loading open={loading} />
      <Loading open={isLoading} />
      {data?.data?.length > 1 ?
        <TableContainer component={Paper}>
          <Confirm
            open={alertOpen}
            title="Confirm Deletion"
            cancelCta={{ title: "Cancel", onClick: () => setAlertOpen(false) }}
            confirmCta={{
              title: "Confirm", onClick: () => {
                setLoading(true); mutate(userId, {
                  onSuccess: () => { toast.success("user delete successfully"); setAlertOpen(false) },
                  onSettled: () => setLoading(false)
                })
              }
            }}
            caption="are you sure you want to remove this user?"
            onChange={e => setAlertOpen(e)}
          />
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell scope="th" sx={{ fontWeight: "bold" }} align="center">#</TableCell>
                <TableCell scope="th" sx={{ fontWeight: "bold" }} align="center">Name</TableCell>
                <TableCell scope="th" sx={{ fontWeight: "bold" }} align="center">Email</TableCell>
                <TableCell scope="th" sx={{ fontWeight: "bold" }} align="center">Roles</TableCell>
                <TableCell scope="th" sx={{ fontWeight: "bold" }} align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.data?.length > 0 && data?.data?.map((user: any, index: number) => {
                return email !== user.email && <TableRow key={user?.id}>
                  <TableCell align="center">{index}</TableCell>
                  <TableCell align="center">{user?.name}</TableCell>
                  <TableCell align="center">{user?.email}</TableCell>
                  <TableCell align="center">{user?.roles?.map((role: string, index: number) => <p key={index}>{role} </p>)}</TableCell>
                  <TableCell align="center"><Button onClick={() => deleteHandler(user.id)}>Delete</Button></TableCell>
                </TableRow>
              })}
            </TableBody>
          </Table>
        </TableContainer> : <>no user found </>}
    </>
  )
}

export default ShowUsers
