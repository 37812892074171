import { Header } from "./Header";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";

const PublicLayout = () => {
  return (
    <>
      <Header />
      <Box sx={{ p: 2 }}>
        <Outlet />
      </Box>
    </>
  );
};

export default PublicLayout;
