import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { SubmitHandler, useForm } from "react-hook-form";
import { useSignInMutation } from "./useSignIn.mutation";
import { useState } from "react";
import {Loading} from "components/common";

type Inputs = {
  email: string;
  password: string;
};

function Login() {
  const { register, handleSubmit } = useForm<Inputs>();
  const { mutate } = useSignInMutation();
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const { email, password } = data;
    setLoading(true)
    mutate({ email, password }, {
      onError: () => {
        setLoading(false)
      },
      onSuccess: () => setLoading(false)
    });
  };

  return (
    <Container component="main" maxWidth="sm">
      <Loading open={loading} />
      <Box
        sx={{
          boxShadow: 3,
          borderRadius: 2,
          px: 4,
          py: 6,
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <TextField
              margin="normal"
              fullWidth
              required
              id="email"
              label="Email Address"
              autoComplete="email"
              autoFocus
              {...register("email")}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              {...register("password")}
              label="Password"
              type="password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
          </Box>
        </form>
      </Box>
    </Container>
  );
}

export default Login;
