export enum ApiGetRoutes {
  categories = "/category",
  inventory = "/inventory",
  order = "/order/:orderId",
  getInventory = "/inventory/:inventoryId",
  getLastOrder = "/last-order",
  getRoles = "/user-roles",
  getEmptyInventory = "/empty-inventory",
  orderSellers = "/order-sellers",
  users = "/users"
}

export enum ApiPostRoutes {
  createInventory = "/inventory",
  login = "/login",
  signup = "/signup",
  createOrder = "/order",
  orders = "/orders",
  inventorySearchByName = "/inventory/name/search",
  inventorySearchByBarcode = "/inventory/barcode/search",
  logout = "/logout",
  orderById = "/get-order",
}

export enum apiPutRoutes {
  updateInventory = "/inventory/update",
}

export enum apiDeleteRoutes {
  deleteInventory = "/inventory/:inventoryId",
  deleteUser = "/delete-user/:id",
  deleteOrder = "/order/:id",
}
