import axios from "axios";
import { QueryClient } from "react-query";
import { matchPath } from "react-router-dom";

const defaultQueryFn = async ({ queryKey }: any) => {
  const { data } = await axios.get(`${queryKey[0]}`);
  return data;
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn,
    },
  },
});

export function useIsPathValid(path: string, routePattern: string) {
  const match = matchPath({ path: routePattern }, path);
  return match !== null;
}
