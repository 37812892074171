import { ApiGetRoutes, apiDeleteRoutes } from "api/routes";
import axios from "axios";
import { useMutation, useQueryClient } from "react-query";


export function useDeleteUser() {
  const queryClient = useQueryClient()
  
  return useMutation((id: any) => {
    return axios.delete(apiDeleteRoutes.deleteUser.replace(":id", id));
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(ApiGetRoutes.users)
    },
  });
}
  