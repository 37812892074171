import { ErrorMessage } from "@hookform/error-message";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { ApiGetRoutes } from "api/routes";
import { CustomTextField } from "components/common";
import { useViewPorts } from "hooks/useViewPorts";
import { ReactElement } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { Container } from "./CreateInventory.styles";
import { useCreateInventoryMutation } from "./useCreateInventory.mutation";
import { numberInputOnWheelPreventChange } from "global/events";

interface Category {
  id: number;
  name: string;
}

type Inputs = {
  id: number;
  name: string;
  barcode?: any;
  quantity: string;
  category: number;
  price: string;
};

export function CreateInventory(): ReactElement {
  const { data, isLoading } = useQuery({ queryKey: [ApiGetRoutes.categories] });
  const { mutate } = useCreateInventoryMutation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Inputs>();
  const { isDesktop } = useViewPorts();

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const mappedData: any = {
      category_id: data.category,
      name: data.name,
      quantity: data.quantity,
      barcode: data.barcode,
      price: data.price,
    };
    mutate(mappedData, {
      onSuccess: () => {
        toast.success("inventory created successfully");
        toast.success(`your barcode is ${data.barcode} for ${data.name}`);
        reset();
      },
      onError: (error: any) => {
        const apiError = error.response.data.msg;
        if (apiError.name && apiError.barcode) {
          toast.error("name and barcode already taken");
        } else if (apiError.name) {
          toast.error("name already taken");
        } else {
          toast.error("barcode already taken");
        }
      },
    });
  };

  return (
    <Container>
      <Typography variant={isDesktop ? "h3" : "h6"} textAlign="center">
        Create inventory
      </Typography>
      {isLoading ? (
        <>loading...</>
      ) : (
        <>
          <form style={{ padding: "2rem" }} onSubmit={handleSubmit(onSubmit)}>
            <FormControl sx={{ mb: "2rem", width: "100%" }}>
              <InputLabel id="demo-simple-select-label">category</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="category"
                defaultValue="1"
                {...register("category", {
                  required: "this is required",
                })}
              >
                {(data as Category[]).map((item: any) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
              <ErrorMessage
                errors={errors}
                name="category"
                render={({ message }) => (
                  <Typography sx={{ color: "red" }}>{message}</Typography>
                )}
              />
              <CustomTextField
                sx={{ marginTop: "1rem" }}
                label="name"
                {...register("name", { required: "name is required." })}
              />
              <ErrorMessage
                errors={errors}
                name="name"
                render={({ message }) => (
                  <Typography sx={{ color: "red" }}>{message}</Typography>
                )}
              />
              <CustomTextField
                sx={{ marginTop: "1rem" }}
                label="barcode"
                {...register("barcode", {
                  required: "barcode is required.",
                  minLength: {
                    value: 12,
                    message: "minimum 12 digit is allowed",
                  },
                  maxLength: {
                    value: 12,
                    message: "maximum 12 digit is allowed",
                  },
                })}
                type="number"
                onWheel={numberInputOnWheelPreventChange}
              />
              <ErrorMessage
                errors={errors}
                name="barcode"
                render={({ message }) => (
                  <Typography sx={{ color: "red" }}>{message}</Typography>
                )}
              />

              <CustomTextField
                sx={{ marginTop: "1rem" }}
                type="number"
                onWheel={numberInputOnWheelPreventChange}
                label="price"
                {...register("price", {
                  required: "price is required",
                })}
              />
              <ErrorMessage
                errors={errors}
                name="price"
                render={({ message }) => (
                  <Typography sx={{ color: "red" }}>{message}</Typography>
                )}
              />
              <CustomTextField
                sx={{ marginTop: "1rem" }}
                type="number"
                onWheel={numberInputOnWheelPreventChange}
                label="quantity"
                {...register("quantity", {
                  required: "quantity is required",
                })}
              />
              <ErrorMessage
                errors={errors}
                name="quantity"
                render={({ message }) => (
                  <Typography sx={{ color: "red" }}>{message}</Typography>
                )}
              />
              <Button
                sx={{
                  mt: 2,
                  width: { xs: "100%", md: "200px" },
                  alignSelf: "center",
                }}
                variant="contained"
                type="submit"
              >
                Submit
              </Button>
            </FormControl>
          </form>
        </>
      )}
    </Container>
  );
}
