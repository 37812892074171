import { Box, Button, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { OrdersTable } from "./OrdersTable";
import { CustomTextField, Loading } from "components/common";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { useOrderByIdMutation } from "./useOrderById.mutation";
import { useNavigate } from "react-router-dom";
import { ProtectedRoutesTypes } from "routes/protected/types";
import { useOrderSellers } from "./useOrderSellers.query";
import { useOrdersMutation } from "./userOders.mutation";
import {numberInputOnWheelPreventChange} from "global/events";

export function Orders() {
  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs().startOf("day"));
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs().endOf("day"));
  const [orderNumber, setOrderNumber] = useState("");
  const { mutate: orderByIdMutation } = useOrderByIdMutation();
  const { mutate, data, isLoading }= useOrdersMutation();
  const navigate = useNavigate();
  const { data: orderSellersData } = useOrderSellers();
  const [cashierName, setCashierName] = useState<string>("all");
  const cashiers = orderSellersData?.data || [];
  const ordersResponseData = data?.data
  const [response, setResponse] = useState<any>(null);
  
  useEffect(() => {
    mutate({
      startDate:startDate?.format("DD/MM/YYYY"),
      endDate:endDate?.format("DD/MM/YYYY"),
    });
  }, []);

  function submitHandler() {
    mutate({
      startDate:startDate?.format("DD/MM/YYYY"),
      endDate:endDate?.format("DD/MM/YYYY"),
      order_by: cashierName === "all" ? null : cashierName
    });
  }

  function handleKeyDown(e: any) {
    if (e.key === 'Enter') {
      billHandler();
    }
  };

  function billHandler() {
    orderByIdMutation({ orderId: orderNumber }, {
      onSuccess: () => navigate(ProtectedRoutesTypes.billing.billingOrder.abs.replace(":orderId", orderNumber))
    })
  }

  useEffect(() => {
   if(ordersResponseData){
    setResponse(ordersResponseData)
   }
  }, [ordersResponseData]);

  return (
    <Box sx={{ p: "2rem" }}>
      <Loading open={isLoading} />
      <Typography variant="h1" sx={{ textAlign: "center" }} >Orders</Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DatePicker", "DatePicker"]}>
          <DatePicker
            value={startDate}
            onChange={(newValue) => setStartDate(newValue)}
            label="start date"
            format="DD/MMM/YYYY"
            sx={{ width: { sm: "100%", lg: "200px" } }}

          />
          <DatePicker
            sx={{ width: { sm: "100%", lg: "200px" } }}
            label="end date"
            value={endDate}
            onChange={(newValue) => setEndDate(newValue)}
            format="DD/MMM/YYYY"
          />
          <FormControl>
            <InputLabel id="demo-simple-select-label">Seller</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={cashierName}
              label="Seller"
              sx={{ width: { sm: "100%", lg: "200px" } }}
              onChange={e => setCashierName(e.target.value)}
            >
              {cashiers?.length > 0 && cashiers.map((cashier: any) => {
                return <MenuItem value={cashier.order_by}>{cashier.order_by}</MenuItem>
              })}
              <MenuItem value={"all"}>All</MenuItem>
            </Select>
          </FormControl>
          <Button
            sx={{ width: { sm: "100%", lg: "100px" } }}
            onClick={submitHandler} variant="contained">
            submit
          </Button>
          <CustomTextField
            label="search by Order number"
            onKeyDown={handleKeyDown}
            value={orderNumber}
            sx={{ width: { sm: "100%", lg: "200px" } }}
            type="number"
            onWheel={numberInputOnWheelPreventChange}
            onChange={(e: any) => setOrderNumber(e.target.value)}
            autoFocus
            InputProps={{
              endAdornment: (
                <ArrowRightIcon
                  onClick={billHandler}
                  sx={{
                    backgroundColor: "black",
                    color: "white",
                    cursor: "pointer",
                  }}
                />
              ),
            }}
          />
          <Typography sx={{ textAlign: "center", whiteSpace: "nowrap", flexGrow: 1, width: { sm: "100%", lg: "200px" } }} variant="h4">Grand Total: {response?.netTotal?.toLocaleString() || 0}</Typography>
        </DemoContainer>
      </LocalizationProvider>

      <Box>{!isLoading && <OrdersTable setResponse={setResponse} data={response?.orders} />}</Box>
    </Box>
  );
}
