import { Box, Button } from "@mui/material";
import { useUserProfile } from "global/useUserProfile";

const Home = () => {
  const {name,email} = useUserProfile();

  return (
    <Box sx={{ pt: 1 }}>
      <Button> button 1</Button>
      <Button> button 2</Button>
      <h3> welcome {name} your email is {email}</h3>
    </Box>
  );
};

export default Home;
