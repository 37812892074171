import { ToastContainer } from "react-toastify";
import { Container, Typography } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import { Close } from "@mui/icons-material";

const CloseToastButton = ({ closeToast }:any) => (
  <Typography variant="h6" sx={{ cursor:"pointer" , p: 2 }} onClick={closeToast}>
    <Close />
  </Typography>
);
export const ToastProvider = () => {
  return (
    <Container data-testid="toast-container">
      <ToastContainer position="top-center" closeButton={CloseToastButton} autoClose={3000} hideProgressBar />
    </Container>
  );
};
