import { ApiGetRoutes } from "api/routes";
import axios from "axios";
import { useQuery } from "react-query";

function userRoles(){
  return axios.get(ApiGetRoutes.getRoles)
}
export function useUserRoles(){
  return useQuery(ApiGetRoutes.getRoles, userRoles)
}
