import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useSearchInventoryByName } from "components/Protected/Inventory/ShowInventory/searchInventoryByName.mutation";
import { useState, useEffect, useCallback } from "react";
import _ from "lodash";

interface ProductNameProps {
  onNameChange: any;
}



export default function ProductName({ onNameChange }: ProductNameProps) {
  const { mutate, isLoading }: any = useSearchInventoryByName();
  const [nameSearch, setNameSearch] = useState("");
  const [options, setOptions] = useState([]);
  const [response, setResponse] = useState([]);
  const [key, setKey] = useState(0);

  const debouncedMutate = useCallback(
    _.debounce((name: any) => {
      if (name.length > 0) {
        mutate(
          { name },
          {
            onSuccess: (data: any) => {
              const response = data?.data;
              const mappedData =
                (response?.length > 0 &&
                  response.map((data: any) => ({
                    label: data?.name,
                    value: data?.id,
                  }))) ||
                [];
              setOptions(mappedData);
              setResponse(response);
            },
          },
        );
      }
    }, 500),
    [mutate],
  );

  function onChange(e: any, v: any) {
    const selectedResponse = response.find((elem: any) => elem.id === v?.value);
    if (v) onNameChange(selectedResponse);
    setKey(Math.random());
  }

  useEffect(() => {
    debouncedMutate(nameSearch);
  }, [debouncedMutate, nameSearch]);

  return (
    <Autocomplete
      key={key}
      onChange={onChange}
      id="combo-box-demo"
      options={options}
      loading={isLoading}
      sx={{ width: { xs: "100%", lg: "30%" } }}
      ListboxProps={{ style: { maxHeight: 550 } }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="product name"
          InputLabelProps={{  shrink: true }}
          value={nameSearch}
          onChange={(e: any) => setNameSearch(e.target.value)}
        />
      )}
    />
  );
}
