import { ApiGetRoutes } from "api/routes";
import axios from "axios";
import { useQuery } from "react-query";

function orderSellers() {
  return axios.get(ApiGetRoutes.orderSellers);
}

export function useOrderSellers() {
  return useQuery(ApiGetRoutes.orderSellers, orderSellers);
}
