import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { CustomLink } from "components/common";
import { useLogout } from "hooks/useLogout";
import * as React from "react";
import { ProtectedRoutesTypes } from "routes/protected/types";
import logoImage from "assets/common/logo.png";
import { useUserProfile } from "global/useUserProfile";
import _ from "lodash";
import { Roles } from "global/constants";




const settings = [
  {
    id: 1,
    title: "logout",
  }
];

function Header() {
  
  const pages = [
    {
      id: 1,
      title: "Billing",
      link: ProtectedRoutesTypes.billing
    },
    {
      id: 2,
      title: "Orders",
      link: ProtectedRoutesTypes.orders
    },
    {
      id: 3,
      title: "Inventory",
      link: ProtectedRoutesTypes.inventory
    },
    {
      id: 4,
      title: "Users",
      link: ProtectedRoutesTypes.users
    },
  ];

  
  const {name,userRoles} = useUserProfile();
  const { mutate } = useLogout()
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  function settingHandler(settingName: string): void {
    if (settingName === settings[0].title) {
      mutate()
    }
  }
  const routes = userRoles.includes(Roles.Moderator) ? pages.splice(0,1) : [...pages]

  return (
    <AppBar position="static">
      <Container sx={{ maxWidth: "100% !important",background: "white" }}>
        <Toolbar sx={{ gap: "25px" }} disableGutters>
          <Box component="img" sx={{ display: {xs: 'none', md: 'block'}, width: "100px" }} src={logoImage} alt="" />
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              sx={{ color: "black" }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {routes.map((page) => (
                <MenuItem key={page.id} onClick={handleCloseNavMenu}>
                  <CustomLink to={page.link.abs} >{page.title}</CustomLink>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
          <Box component="img" sx={{  width: "100px" }} src={logoImage} alt="" />
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {routes.map((page) => (
              <CustomLink sx={{ mr: 2 }} to={page.link.abs} onClick={handleCloseNavMenu} key={page.id}>{page.title}</CustomLink>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt={_.capitalize(name)} src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <Box key={setting.id} onClick={() => settingHandler(setting.title)}>
                  <MenuItem onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">{setting.title}</Typography>
                  </MenuItem>
                </Box>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Header;
