import { Routes, Route } from "react-router-dom";
import { PublicRoutesType } from "./types";
import { AboutPage, HomePage, LoginPage } from "pages/public";
import { PublicLayout } from "layout";
import { RequiresLoggedOut } from "authentication/RequiresLoggedOut";

const PublicRoutes = () => {
  return (
    <RequiresLoggedOut>
      <Routes>
        <Route element={<PublicLayout />}>
          <Route index element={<HomePage />} />
          <Route path={PublicRoutesType.about.rel} element={<AboutPage />} />
          <Route path={PublicRoutesType.login.rel} element={<LoginPage />} />
          <Route path={PublicRoutesType.login.rel} element={<LoginPage />} />
          <Route path={PublicRoutesType.others.rel} element={<>no routes found public</>} />
        </Route>
      </Routes>
    </RequiresLoggedOut>
  );
};

export default PublicRoutes;
