import { ApiGetRoutes } from "api/routes";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { ProtectedRoutesTypes } from "routes/protected/types";
import { CustomTextField, PrintBarcode } from "components/common";
import { Autocomplete, Box, Button } from "@mui/material";
import { useUpdateInventory } from "./useUpdateInventory.mutation";
import { toast } from "react-toastify";
import Barcode from "react-barcode";
import ReactToPrint from "react-to-print";
import {numberInputOnWheelPreventChange} from "global/events";

export function EditInventory() {
  const { inventoryId } = useParams();
  const { data, failureCount, isLoading }: any = useQuery({
    queryKey: [ApiGetRoutes.getInventory.replace(":inventoryId", `${inventoryId}`)],
  });
  let componentRef: any = useRef();

  const navigate = useNavigate();
  const [response, setResponse] = useState<any>({});
  const { data: categories }: any = useQuery({
    queryKey: [ApiGetRoutes.categories],
  });
  const { mutate } = useUpdateInventory()
  const mappedData = categories?.map((item: any) => ({
    label: item?.name,
    value: item?.id,
  }));

  useEffect(() => {
    if (failureCount === 1) {
      toast.error("Something went wrong");
      navigate(ProtectedRoutesTypes.inventory.edit.abs);
    }
  }, [failureCount, navigate]);

  useEffect(() => {
    if (data) {
      setResponse({ ...data?.inventory });
    }
  }, [data]);

  const onCategoryChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    setResponse((prevState: any) => ({
      ...prevState,
      category: { id: newValue?.value, name: newValue?.label },
    }));
  };

  function submitHandler(e: any) {
    e.preventDefault();
    const object = {
      name: response.name,
      quantity: response.quantity,
      price: response.price,
      category_id: response.category.id,
      inventoryId: response.id
    }
    mutate(object)
  }

  return (
    <form style={{ padding: "1rem" }} onSubmit={submitHandler}>
      {!isLoading && <Box sx={{ display: "none" }}><PrintBarcode ref={componentRef} name={data?.inventory?.name} barcode={data?.inventory?.barcode} /></Box>}

      <CustomTextField
        label="Name"
        value={response?.name}
        onChange={(e: any) =>
          setResponse((prevState: any) => ({ ...prevState, name: e.target.value }))
        }
      />
      {mappedData && <Autocomplete
        onChange={onCategoryChange}
        value={{ label: response?.category?.name, value: response?.category?.id }}
        disablePortal
        id="combo-box-demo"
        disableClearable
        options={mappedData}
        isOptionEqualToValue={(option, value: any) => option.value === value.value}
        sx={{ width: "100%", mt: 3 }}
        ListboxProps={{ style: { maxHeight: 550 } }}
        renderInput={(params) => (
          <CustomTextField
            value={response?.category?.name}
            {...params}
            label="Category"
          />
        )}
      />}
      <CustomTextField
        sx={{ mt: 2 }}
        label="price"
        type="number"
        onWheel={numberInputOnWheelPreventChange}
        value={response?.price}
        onChange={(e: any) =>
          setResponse((prevState: any) => ({ ...prevState, price: parseInt(e.target.value) }))
        }
      />
      <CustomTextField
        sx={{ mt: 2 }}
        label="quantity"
        type="number"
        onWheel={numberInputOnWheelPreventChange}
        value={response?.quantity}
        onChange={(e: any) =>
          setResponse((prevState: any) => ({ ...prevState, quantity: parseInt(e.target.value) }))
        }
      />
      <Box sx={{ textAlign: "center", mt: 4 }}>
        <Barcode format="CODE128" value={data?.inventory?.barcode} />,
      </Box>

      <Box sx={{ display: "flex", gap: "4rem", flexWrap: "wrap", justifyContent: "center" }}>
        <Box sx={{ width: { sm: "100%", lg: "200px" } }}>
          <Button variant="contained" sx={{ mt: 2, display: "flex", m: "0 auto", width: { sm: "100%", lg: "200px" } }} type="submit">submit</Button>
        </Box>
        <Box sx={{ width: { sm: "100%", lg: "200px" } }}>
          {data?.inventory?.barcode && <ReactToPrint
            trigger={() => <Button variant="outlined" sx={{ mt: 2, display: "flex", m: "0 auto", width: { sm: "100%", lg: "200px" } }} >Print Barcode</Button>}
            content={() => componentRef.current || null}
          />}
        </Box>
      </Box>

    </form>
  );
}
