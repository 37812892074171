import { PublicRoutesType } from "routes/public/types";
import _ from "lodash";
import { ProtectedRoutesTypes } from "routes/protected/types";
import { useUserProfile } from "global/useUserProfile";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

interface RequiresLoggedOutProps {
  children: any;
}

export const RequiresLoggedOut = ({ children }: RequiresLoggedOutProps) => {

  const existingProtectedRoutes = _.map(ProtectedRoutesTypes, 'abs');
  const existingPublicRoutes = _.map(PublicRoutesType, 'abs');
  const location = useLocation()
  const navigate = useNavigate();
  const { loggedIn } = useUserProfile()

  useEffect(() => {
    if ((existingProtectedRoutes.includes(location.pathname)) && loggedIn) {
      navigate(ProtectedRoutesTypes.home.abs)
    }

    if ((existingPublicRoutes.includes(location.pathname)) && loggedIn) {
      navigate(ProtectedRoutesTypes.home.abs)
    }

    if ((existingPublicRoutes.includes(location.pathname)) && !loggedIn) {
      navigate(PublicRoutesType.home.abs)
    }
  }, []);

  return children
};
