import { Users } from 'components/Protected';
import React from 'react'

function UsersPage() {
  return (
    <Users />
  )
}

export default UsersPage;
