import { ErrorMessage } from "@hookform/error-message";
import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { CustomTextField } from 'components/common';
import { useForm } from "react-hook-form";
import { useUserRoles } from './useUserRoles.query';
import { useCreateUser } from './userCreateUser.mutation';

function CreateUser() {
  const { data } = useUserRoles()
  const roles = data?.data
  const { register, handleSubmit, watch, formState: { errors, isValid } } = useForm();
  const { mutate } = useCreateUser();

  function onSubmit(data: any) {
    const {name,email,password,role} = data
    mutate({
      name,
      email,
      password,
      role,
    })
  }


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CustomTextField
        label="name"
        sx={{ mt: 2, mb: 2 }}
        {...register("name", { required: "this is required" })}
      />
      <ErrorMessage errors={errors} name="name" />
      <FormControl
        sx={{ mt: 2, mb: 2 }}
        fullWidth>
        <InputLabel id="demo-simple-select-label">Roles</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          label="Roles"
          {...register("role", { required: "this is required" })}
          defaultValue=""
        >
          {
            roles?.length > 0 && roles.map((role: any) => {
              return (
                <MenuItem key={role.id} value={role.name}>{role.name}</MenuItem>
              )
            })
          }
        </Select>
      </FormControl>
      <ErrorMessage errors={errors} name="role" />
      <CustomTextField
        label="email"
        sx={{ mt: 2, mb: 2 }}
        {...register("email", {
          required: "this is required", pattern: {
            value: /\S+@\S+\.\S+/,
            message: "Entered value does not match email format"
          }
        })}
      />
      <ErrorMessage errors={errors} name="email" />
      <CustomTextField
        label="password"
        type="password"
        sx={{ mt: 2, mb: 2 }}
        {...register("password", {
          required: "this is required"
        })}
      />
      <ErrorMessage errors={errors} name="password" />
      <CustomTextField
        label="verify password"
        type="password"
        sx={{ mt: 2, mb: 2 }}
        {...register("passwordVerification", {
          required: "this is required",
          validate: (val: string) => {
            if (watch('password') !== val) {
              return "Your passwords do no match";
            }
          },
        })}
      />
      <ErrorMessage errors={errors} name="passwordVerification" />
      <Button disabled={!isValid} type="submit" variant="contained" sx={{ width: "200px", ml: "auto", mr: "auto", mt: 2, mb: 2, display: "block" }}>Create User</Button>
    </form>
  )
}

export default CreateUser;
