import { List } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ProtectedRoutesTypes } from 'routes/protected/types';
import { CustomizedListItem } from './SideNavigation.styles';
import { useViewPorts } from 'hooks/useViewPorts';
import { useUserProfile } from 'global/useUserProfile';
import { Roles } from 'global/constants';


function SideNavigation(): any {
  const navigate = useNavigate();
  const { isDesktop } = useViewPorts();
  const { userRoles } = useUserProfile()
  
  return isDesktop && <List sx={{ display: { sm: "none", lg: "block" } }}>
    <CustomizedListItem onClick={() => navigate(ProtectedRoutesTypes.billing.abs)}>Billing</CustomizedListItem>
    {userRoles.includes(Roles.Admin) &&
      <>
        <CustomizedListItem onClick={() => navigate(ProtectedRoutesTypes.orders.abs)}>Orders</CustomizedListItem>
        <CustomizedListItem onClick={() => navigate(ProtectedRoutesTypes.inventory.abs)}>Inventory</CustomizedListItem>
        <CustomizedListItem onClick={() => navigate(ProtectedRoutesTypes.users.abs)}>Users</CustomizedListItem>
      </>
    }
  </List>
}

export default SideNavigation

