import { ApiGetRoutes, apiDeleteRoutes } from "api/routes";
import axios from "axios";
import { useMutation, useQueryClient } from "react-query";

export function useInventoryDelete() {

  const queryClient = useQueryClient()
  
  return useMutation((inventoryId: string) => {
    return axios.delete(apiDeleteRoutes.deleteInventory.replace(":inventoryId", inventoryId));
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(ApiGetRoutes.inventory)
    },
  });
}
