import { Button, Typography } from "@mui/material";
import { CustomTextField } from "components/common";
import { useViewPorts } from "hooks/useViewPorts";
import _ from "lodash";
import { useState } from "react";
import {numberInputOnWheelPreventChange} from "global/events";

const customBillInitialState = { name: "", quantity: 0, price: 0 }

export default function CustomBilling({ updateData, data }: any) {
  const [customBill, setCustomBill] = useState(customBillInitialState);
  const {isDesktop} = useViewPorts();

  // Function to reset the form fields
  const resetForm = () => {
    setCustomBill(customBillInitialState);
  }

  function submitHandler(e: any) {
    e.preventDefault();
    const existingData = [...data];
    const checkIfDataExists = _.find(existingData, function (o: any) {
      return o.name === customBill.name;
    });

    if (!checkIfDataExists) {
      updateData((prevState: any) => [
        ...prevState,
        { name: customBill.name, price: customBill.price, quantity: customBill.quantity },
      ]);
    } else {
      const index = _.findIndex(existingData, function (o: any) {
        return o.name === customBill.name;
      });
      existingData[index] = {
        ...existingData[index],
        quantity: existingData[index]["quantity"] + 1,
      };
      updateData(existingData);
    }

    resetForm();
  }

  return (
    <form onSubmit={submitHandler}>
      <Typography variant={isDesktop ? "h3" : "h6"} sx={{ textAlign: "center", display: "block", mb: 5 }}>Custom Billing</Typography>
      <CustomTextField
        label="name"
        sx={{ mt: 2 }}
        value={customBill.name}
        onChange={(e: any) => setCustomBill((prevState) => ({ ...prevState, name: e.target.value }))}
      />
      <CustomTextField
        label="quantity"
        sx={{ mt: 2 }}
        value={Number(customBill.quantity).toString()}
        onChange={(e: any) => setCustomBill((prevState) => ({ ...prevState, quantity: parseInt(e.target.value) }))}
        type="number"
        onWheel={numberInputOnWheelPreventChange}
      />
      <CustomTextField
        label="price"
        type="number"
        sx={{ mt: 2 }}
        onWheel={numberInputOnWheelPreventChange}
        value={Number(customBill.price).toString()}
        onChange={(e: any) => setCustomBill((prevState) => ({ ...prevState, price: parseInt(e.target.value) }))}
      />
      <Button   disabled={!(customBill.name.length > 0 && customBill.price > 0 && customBill.quantity > 0)} sx={{ mt: 2 ,mb: 2}} variant="contained" type="submit">submit</Button>
    </form>
  )
}
