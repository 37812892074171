import { forwardRef } from 'react'; // Import forwardRef
import { TextField } from "@mui/material";

// Use forwardRef to wrap your component
const CustomTextField = forwardRef(({ InputLabelProps = {}, sx = {}, ...props }: any, ref) => {
  return (
    <TextField
      InputLabelProps={{ ...InputLabelProps, shrink: true }}
      sx={{ width: "100%", ...sx }}
      {...props}
      ref={ref} // Forward the ref to the TextField
    />
  )
});

export default CustomTextField;
