import { Box, Typography } from '@mui/material'
import React from 'react'
import { Inventory } from './Inventory'
import { useEmptyInventory } from './useEmptyInventory'
import { Loading } from 'components/common'

const EmptyInventory = () => {
  const {data, isLoading} = useEmptyInventory();
  return (
    <Box sx={{ p: 2 }}>
      <Typography variant='h3' sx={{ textAlign: "center", mb: 4, mt :4 }}>Empty Inventory</Typography>
      {isLoading && <Loading  open={isLoading}/>}
      {!isLoading && data?.data?.inventory?.length >0 ? <Inventory data={data?.data?.inventory} />: <>no record found</>}
    </Box>
  )
}

export default EmptyInventory

