import { Header } from "./Header";
import { SideNavigation } from "./SideNavigation";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";

const ProtectedLayout = () => {
  return (
    < >
      <Header />
      <Box sx={{ padding: 2, display: {xs: "initial", md: "flex"} }}>
        <SideNavigation />
        <Box flexGrow={1} marginTop="1rem">
          <Outlet />
        </Box>
      </Box>
    </>
  );
};

export default ProtectedLayout;
