import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Box, Button, TextField, Typography } from "@mui/material";
import _ from "lodash";
import { ReactElement, useEffect, useRef, useState } from "react";
import { BillingTable } from "./BillingTable";
import { ProductName } from "./ProductName";
import { useCreateOrder } from "./useCreateOrder.mutation";
import CustomBilling from "./CustomBilling";
import { useViewPorts } from "hooks/useViewPorts";
import { useSearchBarcode } from "components/Protected/Inventory/ShowInventory/searchInventoryByBarcode.mutation ";
import { Loading } from "components/common";
import { useLastOrder } from "./useLastOrder.query";
import ReactToPrint from "react-to-print";
import { useNavigate } from "react-router-dom";
import { ProtectedRoutesTypes } from "routes/protected/types";
import Print from "components/common/Print";
import { toast } from "react-toastify";
import { useQuery } from "react-query";
import { ApiGetRoutes } from "api/routes";
import { numberInputOnWheelPreventChange } from "global/events";

export enum Operation {
  add = "add",
  subtract = "subtract",
}

function Billing(): ReactElement {
  const [tableData, setTableData] = useState<any>([]);
  const [barcode, setBarcode] = useState<string>("");
  const [discount, setDiscount] = useState<number>();
  const { isDesktop } = useViewPorts();
  const {
    mutate,
    data: orderData,
    isLoading: mutationLoading,
  } = useCreateOrder();
  const [subtractedAmount, setSubtractedAmount] = useState<number>();
  const { mutate: searchInventoryByCodeMutation, isLoading } =
    useSearchBarcode();
  const { data, refetch } = useLastOrder();
  const navigate = useNavigate();
  let newOrderRef: any = useRef();
  let lastOrderRef: any = useRef();
  const [orderId, setOrderId] = useState(null);
  const [orderResponse, setOrderResponse] = useState<any>(null);
  const { data: orderQueryData } = useQuery({
    queryKey: orderId ? [ApiGetRoutes.order.replace(":orderId", orderId)] : [],
    enabled: !!orderId,
  });
  const printButtonRef = useRef<HTMLButtonElement | null>(null);

  function removeItemFromTable(index: number) {
    const prevdata = [...tableData];
    prevdata.splice(index, 1);
    setTableData(prevdata);
  }

  function onNameChange(response: any) {
    const quantity = parseInt(response.quantity);
    if (quantity > 0) {
      const existingData = [...tableData];
      const checkIfNameAlreadyExists = _.find(existingData, function (o: any) {
        return o.id === response.id;
      });
      if (!checkIfNameAlreadyExists) {
        setTableData((prevState: any) => [
          ...prevState,
          { ...response, quantity: 1 },
        ]);
      } else {
        const index = _.findIndex(existingData, function (o: any) {
          return o.id === response.id;
        });
        existingData[index] = {
          ...existingData[index],
          quantity: existingData[index]["quantity"] + 1,
        };
        setTableData(existingData);
      }
    } else {
      toast.error("empty inventory");
    }
  }

  function onQuantityChange(operation: string, rowId: number) {
    const existingData = [...tableData];
    const index = _.findIndex(existingData, function (o: any) {
      return o.id === rowId;
    });

    if (operation === Operation.add) {
      existingData[index] = {
        ...existingData[index],
        quantity: existingData[index]["quantity"] + 1,
      };
    } else {
      existingData[index] = {
        ...existingData[index],
        quantity: existingData[index]["quantity"] - 1,
      };
    }
    if (existingData[index].quantity === 0) {
      existingData.splice(index, 1);
    }
    setTableData(existingData);
  }

  async function searchBarCodeHandler() {
    setBarcode("");
    searchInventoryByCodeMutation(
      { barcode: parseInt(barcode) },
      {
        onSuccess: ({ data }) => {
          const quantity = parseInt(data.data.quantity);
          if (quantity > 0) {
            const barcodeData = data.data;
            const existingData = [...tableData];
            const checkIfBarCodeAlreadyExists = _.find(
              existingData,
              function (o: any) {
                return o.barcode == barcodeData.barcode;
              },
            );
            if (!checkIfBarCodeAlreadyExists) {
              setTableData((prevState: any) => [
                ...prevState,
                { ...barcodeData, quantity: 1 },
              ]);
            } else {
              const index = _.findIndex(existingData, function (o: any) {
                return o.barcode == barcode;
              });
              existingData[index] = {
                ...existingData[index],
                quantity: existingData[index]["quantity"] + 1,
              };
              setTableData(existingData);
            }
          } else {
            toast.error("empty inventory");
          }
        },
      },
    );
  }

  function onDiscountChange(discount: number) {
    setDiscount(discount);
  }

  function confirmOrderHandler() {
    const mappedData = tableData.map((data: any) => {
      return {
        id: data.id,
        name: data.name,
        price: data.price,
        quantity: data.quantity,
        barcode: data.barcode,
      };
    });
    const object = {
      discount,
      orderDetailsData: mappedData,
      subtraction: subtractedAmount?.toString(),
    };
    mutate(object, {
      onSuccess: () => refetch(),
    });
  }

  function handleKeyDown(e: any) {
    if (e.key === "Enter") {
      searchBarCodeHandler();
    }
  }

  useEffect(() => {
    const order = orderData?.data?.order;
    if (order) setOrderId(order?.id);
  }, [orderData]);

  function handlePrintButtonClick() {
    if (printButtonRef.current) {
      printButtonRef.current.click();
    }
  }

  useEffect(() => {
    if (orderData) {
      setOrderResponse(orderQueryData);
    }
  }, [orderQueryData]);

  useEffect(() => {
    if (orderResponse) {
      handlePrintButtonClick(); // Trigger the print when orderResponse is available
    }
  }, [orderResponse]);

  return (
    <Box padding="2rem">
      <Loading open={mutationLoading} />
      <Box
        sx={{
          display: "flex",
          gap: "2rem",
          flexWrap: { xs: "wrap", lg: "nowrap" },
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "none" }}>
          <Print ref={lastOrderRef} data={data?.data?.data} />
        </Box>
        <Box sx={{ display: "none" }}>
          <Box sx={{ display: "none" }}>
            <Print ref={newOrderRef} data={orderResponse} />
          </Box>
          <ReactToPrint
            trigger={() => (
              <Button ref={printButtonRef} variant="contained">
                Print this out!
              </Button>
            )}
            content={() => newOrderRef.current || null}
            onAfterPrint={() => {
              setTableData([]);
              setBarcode("");
              setDiscount(undefined);
              setSubtractedAmount(undefined);
              toast.success("order created");
              refetch();
            }}
          />
        </Box>

        <Loading open={isLoading} />
        <Box sx={{ width: { xs: "100%", lg: "60%" } }}>
          <Typography
            variant={isDesktop ? "h3" : "h6"}
            sx={{ textAlign: "center", display: "block", mb: 5 }}
          >
            Billing
          </Typography>
          <Box
            sx={{ flexWrap: "wrap" }}
            display="flex"
            justifyContent="center"
            gap="2rem"
            marginBottom="2rem"
          >
            <ProductName onNameChange={onNameChange} />
            <TextField
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ width: { xs: "100%", lg: "30%" } }}
              value={barcode}
              onChange={(e: any) => setBarcode(e.target.value)}
              label="barcode"
              onKeyDown={handleKeyDown}
              onWheel={numberInputOnWheelPreventChange}
              autoFocus
              InputProps={{
                endAdornment: (
                  <ArrowRightIcon
                    onClick={searchBarCodeHandler}
                    sx={{
                      backgroundColor: "black",
                      color: "white",
                      cursor: "pointer",
                    }}
                  />
                ),
              }}
            />
            <Button
              disabled={!(tableData.length > 0)}
              variant="contained"
              onClick={confirmOrderHandler}
            >
              Confirm Order
            </Button>
            <ReactToPrint
              trigger={() => (
                <Button variant="contained">Print last Order</Button>
              )}
              content={() => lastOrderRef.current || null}
              onAfterPrint={() => navigate(ProtectedRoutesTypes.home.abs)}
            />
          </Box>
        </Box>
        <Box sx={{ width: { xs: "100%", lg: "40%" } }}>
          <CustomBilling data={tableData} updateData={setTableData} />
        </Box>
      </Box>
      {tableData.length > 0 && (
        <BillingTable
          discount={discount}
          onDiscountChange={onDiscountChange}
          onQuantityChange={onQuantityChange}
          onRemoveItem={removeItemFromTable}
          data={tableData}
          subtractedAmount={subtractedAmount}
          onSubtractedAmountChange={setSubtractedAmount}
        />
      )}
    </Box>
  );
}

export default Billing;
